import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Keanu from '../images/keanu_reeves.jpg';
import Enrique from '../images/enrique_iglesias.jpg';
import Tyga from '../images/tyga.jpg';
import Anderson_Paak from '../images/anderson.jpg';
import Eddie_Van_Halen from '../images/eddievanhalen.jpg';
import Griffin_Gluck from '../images/griffingluck.jpg';

const QuarterAsians = () => {
return (
        <>
            <Container className="p-2">
                <Row>
                    <Col>
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={Keanu} />
                        <Card.Body>
                            <Card.Title>Keanu Reeves</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Actor</Card.Subtitle>
                            <Card.Text>
                                Keanu is a mix of Chinese and Hawaiian. He's also mistaken as half asian.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={Enrique} />
                        <Card.Body>
                            <Card.Title>Enrique Iglesias</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Singer/Songwriter</Card.Subtitle>
                            <Card.Text>
                                Did you he's actually a quarter Filipino? His Mom is half Filipino, and a socialite no less.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={Tyga} />
                        <Card.Body>
                            <Card.Title>Tyga</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Rapper</Card.Subtitle>
                            <Card.Text>
                                Tyga is a quarter Vietnamese. He has tattoo that says "Vietnam" on his right arm. His grandmother is Vietnamese.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    </Row>
                </Container>

                <Container className="p-2">
                    <Row>
                    <Col>
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={Anderson_Paak} />
                        <Card.Body>
                            <Card.Title>Anderson .Paak</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Singer/Songwriter</Card.Subtitle>
                            <Card.Text>
                                Brendon Paak Anderson is actually 25% Korean. His mother was born in South Korea during the Korean War. Anderson's wife is 100% Korean and their son is Soul loves BTS. 
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>


                    <Col>
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={Eddie_Van_Halen} />
                        <Card.Body>
                            <Card.Title>Eddie Van Halen</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Musician</Card.Subtitle>
                            <Card.Text>
                                Eddie Van Halen's mother was Half Indonesian, making him a quarter. The late musician's parents gave birth to Eddie in the Netherlands an moved to the United States.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                      <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={Griffin_Gluck} />
                        <Card.Body>
                            <Card.Title>Griffin Gluck</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">Actor</Card.Subtitle>
                            <Card.Text>
                                Griffin's Gluck grandfather was a Jewish American <a href= "https://en.wikipedia.org/wiki/Japanophilia" style={ {color: '#8843F2'} } target="_blank">Japanophile</a>. Gluck's father was born and partly raised in Kobe, Japan.
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        </>
    );
}

export default QuarterAsians;