import React from 'react';
import '../style/Navbar.css';
import '../App.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

function Home() {
  return (
    <Container>
      <div className='center mt-3'>
          <h1 className='mt-2' style={{fontSize:"40px"}}>Welcome to quarterasian.com</h1>
          <h6 className='lead' style={{fontSize:"20px"}}>The premier source for all things Quarterasian.</h6>
          <a href='https://twitter.com/messages/compose?recipient_id=3045426691&text=Hey%20Mark' data-screen-name="@markymauro" class="twitter-dm-button"><center><Button variant="dark" style={{outline: 'none', backgroundColor: '#8843F2'}} >Message Me</Button>{' '}</center></a>
        </div>
    </Container>
  );
}

export default Home;
