import React from 'react';
import '../style/Navbar.css';
import { Link } from 'react-router-dom';
import BlankPage from './BlankPage';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';


function renderNavbar() {
  return (
      <>
        <div>
        <Navbar className='navbar' variant="dark" >
          <Container>
          <Navbar.Brand>
            QA
          </Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/quarterasians">
                Quarter Asians
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
    </div>
    </>
  );
}

export default renderNavbar;
