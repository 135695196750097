import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import QuarterAsians from './components/QuarterAsians';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <>
    <Router>
      <Navbar/>
      <Routes>
        <Route path='/' element={ <Home/> }/>
        <Route path='/quarterasians' element={ <QuarterAsians/> }/>
      </Routes>
    </Router>
    </>
  );
}

export default App;
